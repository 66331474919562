import {
  search,
  book,
  details,
  priceDetails,
  ServiceType,
  alternateHotels,
  getAllotmentAvailability,
  searchFlightPool,
  bookableDates,
  tourCodes,
  searchWithErrorMapping,
} from "@qite/tide-client";
import { addDays, addYears, format } from "date-fns";
import { cloneDeep, first } from "lodash";
import {
  BookableDates,
  BookableDatesRequest,
  BookingPackage,
  BookingPackageAddress,
  BookingPackageBookRequest,
  BookingPackageDestination,
  BookingPackageDetailsRequest,
  BookingPackageDossier,
  BookingPackageFlightPool,
  BookingPackageFlightPoolRequest,
  BookingPackageHotelPool,
  BookingPackageItem,
  BookingPackagePax,
  BookingPackageRequest,
  BookingPackageRequestRoom,
  BookingPackageSearchRequest,
  BookingPriceDetails,
  SelectedFlight,
  TideResponse,
  TourCodesRequest,
  TourCodesResponse,
} from "@qite/tide-client/build/types";
import { TideTag } from "../types";
import { BookingPackageAvailability } from "@qite/tide-client/build/types/offer/booking-v2/shared/booking-package-availability";
import { getAge } from "../utils/date-utils";
import { getTideClientConfig } from "../utils/member-and-tide-utils";

const HOST = process.env.GATSBY_TIDE_HOST;
const API_KEY = process.env.GATSBY_TIDE_API_KEY;
const CATALOGIDS = process.env.CATALOGIDS;
const OFFICEID = Number(process.env.OFFICEID);
export const DEFAULT_ROOM: BookingPackageRequestRoom[] = [
  {
    index: 1,
    pax: [
      {
        id: -1,
        gender: 0,
        age: 30,
        guid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        isMainBooker: false,
        initials: "",
        email: "",
        phone: "",
        mobilePhone: "",
        preferredLanguageId: 1,
      },
      {
        id: -1,
        gender: 1,
        age: 30,
        guid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        isMainBooker: false,
        initials: "",
        email: "",
        phone: "",
        mobilePhone: "",
        preferredLanguageId: 1,
      },
    ],
  },
];

export const searchPackages = async (
  tourcodes: string[],
  allotmentTagIds: number[],
  countryId?: number,
  flightsIncluded?: boolean,
  signal?: AbortSignal
): Promise<BookingPackageItem[]> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  let destination: BookingPackageDestination = {} as BookingPackageDestination;
  if (countryId) {
    destination.id = countryId;
    destination.isCountry = true;
  }

  const bookingPackageRequest: BookingPackageRequest<BookingPackageSearchRequest> = {
    officeId: OFFICEID,
    payload: {
      catalogueIds: CATALOGIDS.split(",").map((n) => parseInt(n, 10)),
      rooms: DEFAULT_ROOM,
      serviceType: 24,
      searchType: 1,
      productIds: [],
      productCodes: [],
      fromDate: format(addDays(new Date(), 3), "yyyy-MM-dd"),
      toDate: format(addYears(addDays(new Date(), 3), 1), "yyyy-MM-dd"),
      useExactDates: false,
      productTagIds: [],
      allotmentName: "",
      tourCodes: tourcodes,
      allotmentTagIds: allotmentTagIds,
      includeFlights: flightsIncluded ?? true,
      includeClosedAllotments: false,
      includeFullyBookedAllotments: false,
      onlyCachedResults: process.env.WEBSITE === "Sportreizen" ? false : true,
      destination: destination,
      includeAllTransportTypes: true,
    },
  };

  const packages = await search(tideClientConfig, bookingPackageRequest, signal);
  return packages;
};

export const searchSpecific = async (
  tourcodes: string[],
  allotmentTagIds: number[],
  rooms: BookingPackageRequestRoom[],
  from: string,
  to: string,
  countryId?: number,
  flightsIncluded?: boolean,
  signal?: AbortSignal
): Promise<TideResponse<BookingPackageItem[]>> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  let destination: BookingPackageDestination = {} as BookingPackageDestination;
  if (countryId) {
    destination.id = countryId;
    destination.isCountry = true;
  }

  const bookingPackageRequest: BookingPackageRequest<BookingPackageSearchRequest> = {
    officeId: OFFICEID,
    payload: {
      catalogueIds: CATALOGIDS.split(",").map((n) => parseInt(n, 10)),
      rooms: rooms,
      serviceType: 24,
      searchType: 1,
      productIds: [],
      productCodes: [],
      fromDate: from,
      toDate: to,
      useExactDates: true,
      productTagIds: [],
      allotmentName: "",
      tourCodes: tourcodes,
      allotmentTagIds: allotmentTagIds,
      includeFlights: flightsIncluded ?? true,
      includeClosedAllotments: false,
      includeFullyBookedAllotments: false,
      includeAllAllotments: true,
      onlyCachedResults: false,
      destination: destination,
    },
  };

  const packageSearchResult = await searchWithErrorMapping(tideClientConfig, bookingPackageRequest, signal);
  return packageSearchResult;
};

export const searchPackageDetail = async (
  bookingPackageItem: BookingPackageItem,
  checkExternalAvailability: boolean,
  newRooms?: BookingPackageRequestRoom[],
  preNights?: number,
  postNights?: number,
  setIsDifferentDate?: boolean,
  provideFlights: boolean = false,
  signal: AbortSignal | undefined = undefined
): Promise<TideResponse<BookingPackage>> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const outwardFlight: SelectedFlight = {
    flightCode: bookingPackageItem.outwardFlightCode ?? "",
    flightNumbers: bookingPackageItem.outwardFlightNumbers ?? [],
    startDateTime: bookingPackageItem.outwardFlightStartDate ?? "",
    endDateTime: bookingPackageItem.outwardFlightEndDate ?? "",
  };

  const returnFlight: SelectedFlight = {
    flightCode: bookingPackageItem.returnFlightCode ?? "",
    flightNumbers: bookingPackageItem.returnFlightNumbers ?? [],
    startDateTime: bookingPackageItem.returnFlightStartDate ?? "",
    endDateTime: bookingPackageItem.returnFlightEndDate ?? "",
  };

  const bookingPackageRequest: BookingPackageRequest<BookingPackageDetailsRequest> = {
    officeId: OFFICEID,
    payload: {
      catalogueId: bookingPackageItem.catalogueId ?? 3,
      rooms: newRooms ?? DEFAULT_ROOM,
      searchType: 1,
      productCode: bookingPackageItem.code,
      fromDate: bookingPackageItem.fromDate,
      toDate: bookingPackageItem.toDate,
      preNights: preNights,
      postNights: postNights,
      allotmentName: bookingPackageItem.allotment.name,
      tourCode: bookingPackageItem.allotment.tourCode,
      includeFlights: bookingPackageItem.includedServiceTypes?.includes(ServiceType.flight) ?? true,
      checkExternalAvailability: checkExternalAvailability,
      outwardFlight: provideFlights ? outwardFlight : undefined,
      returnFlight: provideFlights ? returnFlight : undefined,
      cachedAllotmentPriceInfos: bookingPackageItem.allotment.cachedAllotmentPriceInfos,
    },
  };
  // hotel: bookingPackageItem.hotelProductCode ? { code: bookingPackageItem.hotelProductCode, fromDate: first(bookingPackageItem.fromDate.split("T")) ? first(bookingPackageItem.fromDate.split("T"))! : bookingPackageItem.fromDate, toDate: first(bookingPackageItem.toDate.split("T")) ? first(bookingPackageItem.toDate.split("T"))! : bookingPackageItem.toDate } : undefined,

  const packageDetail = await details(tideClientConfig, bookingPackageRequest, signal);
  return packageDetail;
};

export const getFlightPool = async (
  bookingPackageItem: BookingPackageItem,
  rooms: BookingPackageRequestRoom[],
  realTime: boolean,
  signal?: AbortSignal
): Promise<BookingPackageFlightPool> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const bookingPackageFlightPoolRequest: BookingPackageRequest<BookingPackageFlightPoolRequest> = {
    officeId: OFFICEID,
    payload: {
      catalogueIds: [bookingPackageItem.catalogueId],
      rooms: rooms,
      productCode: bookingPackageItem.code,
      tourCode: bookingPackageItem.allotment.tourCode,
      fromDate: bookingPackageItem.fromDate,
      toDate: bookingPackageItem.toDate,
      realTime: realTime,
    },
  };

  const flightPool = await searchFlightPool(tideClientConfig, bookingPackageFlightPoolRequest, signal);
  return flightPool;
};

export const fetchHotelPool = async (transactionId: string, signal?: AbortSignal): Promise<BookingPackageHotelPool> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const hotelPool = await alternateHotels(tideClientConfig, transactionId, signal);
  return hotelPool;
};

export const searchPackagePriceDetail = async (bookingPackage: BookingPackage): Promise<BookingPriceDetails> => {
  const bookingDetails = bookingPackage.options.find((o) => o.isSelected);
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID || !bookingDetails) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const bookingPackageRequest: BookingPackageRequest<BookingPackageBookRequest> = {
    officeId: OFFICEID,
    payload: {
      package: bookingPackage,
      pax: bookingDetails.requestRooms
        .map(function (r) {
          return r.pax;
        })
        .reduce(function (a, b) {
          return a.concat(b);
        }, []),
      status: 0,
      nonTravelPax: [],
      calculateDeposit: true,
      returnPaymentUrl: false,
      redirectUrl: "",
      notifications: [],
      customerRequests: [],
    },
  };

  const packagePrice = await priceDetails(tideClientConfig, bookingPackageRequest);
  return packagePrice;
};

export const bookPackage = async (
  bookingPackage: BookingPackage,
  bookingPriceDetail: BookingPriceDetails,
  stayAtHome: BookingPackagePax,
  mainBooker: BookingPackagePax,
  address: BookingPackageAddress,
  eventId: string,
  affiliateId: string,
  tradeTrackerTag: TideTag,
  agentId?: number
): Promise<BookingPackageDossier> => {
  const bookingDetails = bookingPackage.options.find((o) => o.isSelected);
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID || !bookingDetails) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  let redirectUrl = "";
  if (typeof window !== "undefined" && !agentId) {
    redirectUrl =
      window.location.origin +
      "/boeking" +
      `?Name=${first(bookingDetails.allotmentName.split("["))?.replace(/ /g, "_")}&EventId=${eventId}&amount=${bookingPriceDetail.deposit}${affiliateId && affiliateId !== "" ? "&vrp=" + affiliateId : ""
      }`;
  }

  const nonTravelPax = transformNonTravelPax(stayAtHome);
  setMainBooker(bookingPackage, mainBooker, nonTravelPax);

  const bookingPackageRequest: BookingPackageRequest<BookingPackageBookRequest> = {
    officeId: OFFICEID,
    agentId: agentId,
    payload: {
      package: bookingPackage,
      pax: bookingDetails.requestRooms
        .map(function (r) {
          return r.pax;
        })
        .reduce(function (a, b) {
          return a.concat(b);
        }, []),
      status: agentId ? 2 : 0,
      customStatusId: agentId ? 4 : null,
      nonTravelPax: nonTravelPax,
      address: { ...address },
      calculateDeposit: true,
      returnPaymentUrl: !agentId,
      redirectUrl: redirectUrl,
      tagIds: affiliateId && affiliateId !== "" ? [tradeTrackerTag.tideId] : null,
    } as BookingPackageBookRequest,
  };

  const bookingPackageDossier = await book(tideClientConfig, bookingPackageRequest);
  return bookingPackageDossier;
};

const transformNonTravelPax = (stayAtHome: BookingPackagePax) => {
  const list = [] as BookingPackagePax[];
  if (stayAtHome && stayAtHome.firstName) {
    list.push(stayAtHome);
  }
  return list;
};

const setMainBooker = (bookingPackage: BookingPackage, mainBooker: BookingPackagePax, nonTravelPax: BookingPackagePax[]) => {
  const travellers = bookingPackage?.options.find((o) => o.isSelected)?.requestRooms.flatMap((rr) => rr.pax);
  let packageTraveller = travellers?.find(
    (t) =>
      t.firstName.trim().toLowerCase() == mainBooker?.firstName.trim().toLowerCase() &&
      t.lastName.trim().toLowerCase() == mainBooker.lastName.trim().toLowerCase() &&
      t.dateOfBirth == mainBooker.dateOfBirth
  );

  if (packageTraveller) {
    mainBooker.id = packageTraveller.id;
  } else {
    let nonTravelMainBooker = nonTravelPax?.find(
      (t) =>
        t.firstName.trim().toLowerCase() == mainBooker?.firstName.trim().toLowerCase() &&
        t.lastName.trim().toLowerCase() == mainBooker.lastName.trim().toLowerCase()
    );
    if (nonTravelMainBooker) {
      mainBooker = setTravellerData(nonTravelMainBooker, mainBooker);
    } else {
      // Er kan maar 1 mainbooker per dossier zijn en nu moet die nog mee op reis
      nonTravelPax.push({ ...mainBooker, isMainBooker: false });
    }
    travellers?.forEach((trav) => {
      trav.isMainBooker = false;
      trav.email = "";
      trav.mobilePhone = "";
      trav.phone = "";
    });

    // Voorlopig moeten we nog een mainbooker hebben die meegaat met de reis in Tide
    packageTraveller = first(travellers);
    // packageTraveller = cloneDeep(first(travellers)); Dit kan dus niet, zie hieronder
  }

  if (packageTraveller) {
    // Door dit te doen ging de info van de eerste reiziger volledig verloren. Nu wordt enkel de mainbooker property aangepast van de eerste reiziger. De "echte" hoofdboeker zit nu in de nonTravelPax.
    // if (mainBooker) {
    //   packageTraveller = setTravellerData(packageTraveller, mainBooker);
    //   packageTraveller.id = mainBooker.id;
    // }
    packageTraveller.isMainBooker = true;
    let otherTravellers = travellers?.filter((t) => t.id != packageTraveller?.id);

    if (otherTravellers) {
      otherTravellers.forEach((trav) => {
        trav.isMainBooker = false;
        trav.email = "";
        trav.phone = "";
        trav.mobilePhone = "";
      });
    }
  }
};

const setTravellerData = (packageTraveller: BookingPackagePax, traveller: BookingPackagePax) => {
  packageTraveller.dateOfBirth = traveller.dateOfBirth;
  packageTraveller.age = traveller.dateOfBirth ? getAge(new Date(traveller.dateOfBirth)) : traveller.age;
  packageTraveller.initials = traveller.initials;
  packageTraveller.firstName = traveller.firstName;
  packageTraveller.lastName = traveller.lastName;
  packageTraveller.gender = traveller.gender;
  packageTraveller.initials = traveller.initials;
  packageTraveller.email = traveller.email;
  packageTraveller.phone = traveller.phone;
  packageTraveller.mobilePhone = traveller.mobilePhone;
  packageTraveller.countryOfBirthId = traveller.countryOfBirthId;
  return packageTraveller;
};

export const getTicketAvailabilities = async (eventId: string, productCode: string, signal?: AbortSignal): Promise<BookingPackageAvailability[]> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const availableTickets = await getAllotmentAvailability(tideClientConfig, eventId, productCode, signal);
  return availableTickets;
};

export const getBookableDates = async (productCode: string, allotmentTourCode: string, signal?: AbortSignal): Promise<BookableDates> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const bookableDatesRequest: BookableDatesRequest = {
    productCode: productCode,
    allotmentTourCode: allotmentTourCode,
  };

  const dates = await bookableDates(tideClientConfig, bookableDatesRequest, signal);
  return dates;
};

export const getTourcodes = async (tourcodes: string[], signal?: AbortSignal): Promise<TourCodesResponse> => {
  if (!HOST || !API_KEY || !CATALOGIDS || !OFFICEID) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const tourcodesRequest: TourCodesRequest = {
    tourCodes: tourcodes,
  };

  const response = await tourCodes(tideClientConfig, tourcodesRequest, signal);
  return response;
};
