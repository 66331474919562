import { BookingPackage, BookingPackageItem, BookingPackageRequestRoom } from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { first, orderBy } from "lodash";
import { ProductCardItem, ProductCardItemPackage } from "../components/cardgrid/cards/product-card";
import { TideItemForHardcodedUrl, TideItemForTeam, TideItemForToernooi, TideItemForWedstrijd } from "../types";
import { findGameById, findSeasonGameById, findTournamentById, getChildItemsWithTypename, getSportPath } from "./component";
import { Image } from "../types";
import { getPlaceHolderImage } from "./image-utils";

export const flightReduction = "FLIGHT REDUCTION";
export const hotelReduction = "HOTEL REDUCTION";
export const ticketReduction = "TICKET REDUCTION";

type GroupedBookingPackageItems = {
  [eventId: string]: BookingPackageItem[];
};

export const CreateProductCardItems = (
  packageItems: BookingPackageItem[],
  buttonText: string,
  withRootPath: boolean,
  teams: TideItemForTeam[],
  tournaments: TideItemForToernooi[],
  games: TideItemForWedstrijd[],
  affiliate: string
) => {
  if (packageItems) {
    const groupedItems = groupByEventId(packageItems);

    const newItems = Object.keys(groupedItems).map((eventId) => {
      const firstItem = first(groupedItems[eventId]);
      if (!firstItem) {
        return null;
      }

      const game = findGameById(games, eventId);
      if (!game) {
        return null;
      }

      const seasonGame = findSeasonGameById(game, eventId);
      const homeTeam = teams.find((t) => t.content?.general?.id === game?.content?.general?.homeTeamId);
      const awayTeam = teams.find((t) => t.content?.general?.id === game?.content?.general?.awayTeamId);
      const tournament = findTournamentById(tournaments, seasonGame?.content?.general?.tournamentId);
      let rootPath = process.env.WEBSITE === "Sportreizen" && game ? getSportPath(game) : "/wedstrijden/";

      return {
        title: firstItem.allotment?.name,
        image: game?.content?.general?.thumbnail?.url
          ? game.content.general.thumbnail
          : tournament?.content?.general?.thumbnail?.url
            ? tournament.content.general.thumbnail
            : ({ url: getPlaceHolderImage(), altText: firstItem.allotment?.name, title: firstItem.allotment?.name } as Image),
        homeLogo: homeTeam?.content?.general?.logo,
        awayLogo: awayTeam?.content?.general?.logo,
        path: `${rootPath}${game?.content?.general?.path}?tourcode=${firstItem.allotment?.tourCode}&flights=${firstItem.includedServiceTypes.includes(
          ServiceType.flight
        )}`,
        startDate: new Date(firstItem.fromDate),
        endDate: new Date(firstItem.toDate),
        ticketName: firstItem.accommodationName,
        ticketDate: new Date(firstItem.allotment.startDate),
        dateConfirmed: firstItem.allotment?.customAllotmentStatus === "Datum bevestigd",
        tournamentName: tournament?.name,
        hardcodedUrls: getChildItemsWithTypename("TideItemForHardcodedUrl", seasonGame) as TideItemForHardcodedUrl[],
        packages: groupedItems[eventId].map((item) => {
          return {
            path: `/boeking?ProductCode=${item.code}&CatalogId=${item.catalogueId}&From=${item.fromDate}&To=${item.toDate}&Tourcode=${item.allotment?.tourCode
              }&EventId=${eventId}&ServiceTypes=${item.includedServiceTypes}${affiliate && affiliate !== "" ? "&vrp=" + affiliate : ""}`,
            price: item.averagePricePerPerson,
            hasFlight: item.includedServiceTypes.includes(ServiceType.flight),
            hasHotel: item.includedServiceTypes.includes(ServiceType.hotel),
            hasEvent: item.includedServiceTypes.includes(ServiceType.event),
            buttonText: buttonText,
          } as ProductCardItemPackage;
        }),
      };
    });

    return newItems.filter((f) => f !== null) as ProductCardItem[];
  }
  return;
};

export const getCacheReduction = (bookingPackage: BookingPackage | undefined, type: string) => {
  return (
    (bookingPackage?.options
      .find((o) => o.isSelected)
      ?.groups.flatMap((g) => g.options)
      ?.find((o) => o.line?.productCode === type)?.line?.price ?? 0) / 2
  );
};

export const createDataLayerItemsFromDetails = (bookingPackage: BookingPackage | undefined) => {
  let items = [];
  const option = bookingPackage?.options.find((o) => o.isSelected === true);
  if (option) {
    // ticket
    const ticket = option.rooms.flatMap((r) => r.options).find((o) => o.isSelected === true);
    if (ticket) {
      items.push({
        item_id: ticket.accommodationCode,
        item_name: ticket.accommodationName,
        item_start_date: ticket.from,
        item_end_date: ticket.to,
        item_category: "Ticket",
        quantity: ticket.quantity,
        price: ticket.price,
      });
    }

    //hotel
    const hotelrooms = option.optionUnits.flatMap((o) => o.groups.flatMap((g) => g.options)).filter((o) => o.isHotelPool && o.isSelected);
    if (hotelrooms) {
      hotelrooms.map((h) => {
        items.push({
          item_id: h.line?.productCode,
          item_name: h.line?.productName,
          item_start_date: h.line?.startDate,
          item_end_date: h.line?.endDate,
          item_category: "Hotel",
          item_category2: h.line?.accommodationCode,
          item_category3: h.line?.regimeCode,
          quantity: 1,
          price: h.line?.price,
        });
      });
    }

    //flights
    const outwardFlight = bookingPackage?.outwardFlights.find((o) => o.isSelected);
    if (outwardFlight) {
      items.push({
        item_id: outwardFlight.code,
        item_name: outwardFlight.code,
        item_start_date: outwardFlight.startDateTime,
        item_end_date: outwardFlight.endDateTime,
        item_category: "Flight",
        quantity: option.requestRooms.flatMap((r) => r.pax).length,
        price: outwardFlight.price,
      });
    }
    const returnFlight = bookingPackage?.returnFlights.find((o) => o.isSelected);
    if (returnFlight) {
      items.push({
        item_id: returnFlight.code,
        item_name: returnFlight.code,
        item_start_date: returnFlight.startDateTime,
        item_end_date: returnFlight.endDateTime,
        item_category: "Flight",
        quantity: option.requestRooms.flatMap((r) => r.pax).length,
        price: returnFlight.price,
      });
    }

    // extras
    const extras = option.optionPax.flatMap((o) => o.groups.flatMap((g) => g.options)).filter((o) => o.isSelected);
    if (extras) {
      extras.map((extra) => {
        items.push({
          item_id: extra.line?.productCode,
          item_name: extra.line?.productName,
          item_start_date: extra.line?.startDate,
          item_end_date: extra.line?.endDate,
          item_category: "Extra",
          quantity: 1,
          price: extra.line?.price,
        });
      });
    }

    // reductions
    const cacheTresholds = option.groups
      .find((g) => g.name === "CACHE TRESHOLD")
      ?.options.filter((o) => o.isSelected && o.line && o.line.price !== 0);
    if (cacheTresholds) {
      cacheTresholds.map((cacheTreshold) => {
        items.push({
          item_id: cacheTreshold.line?.productCode,
          item_name: cacheTreshold.line?.productName,
          item_start_date: cacheTreshold.line?.startDate,
          item_end_date: cacheTreshold.line?.endDate,
          item_category: "Reduction",
          quantity: 1,
          price: cacheTreshold.line?.price,
        });
      });
    }
    return items;
  }
};

export const createDataLayerItemsFromSearch = (packageItem: BookingPackageItem, requestRooms: BookingPackageRequestRoom[]) => {
  if (packageItem) {
    let items = [
      {
        item_id: packageItem.allotment?.tourCode,
        item_name: packageItem.allotment?.name,
        item_location: packageItem.name,
        item_start_date: packageItem.allotment?.startDate,
        item_end_date: packageItem.allotment?.endDate,
        item_category: "Ticket",
        quantity: requestRooms.flatMap((r) => r.pax).length,
      },
      {
        item_id: packageItem.hotelProductCode,
        item_name: packageItem.hotelProductCode,
        item_location: packageItem.locationName,
        item_start_date: packageItem.fromDate,
        item_end_date: packageItem.toDate,
        item_category: "Hotel",
        quantity: requestRooms.length,
      },
    ];
    if (packageItem.includedServiceTypes.includes(7)) {
      items.push({
        item_id: packageItem.outwardFlightCode,
        item_name: packageItem.flightDescription,
        item_location: packageItem.airportCode,
        item_start_date: packageItem.outwardFlightStartDate || "",
        item_end_date: packageItem.returnFlightEndDate || "",
        item_category: "Flight",
        quantity: requestRooms.flatMap((r) => r.pax).length,
      });
    }
    return items;
  }
  return null;
};

const groupByEventId = (items: BookingPackageItem[]) => {
  const orderedItems = items
    .filter((i) => i !== null)
    .reduce<GroupedBookingPackageItems>((acc, item) => {
      const eventId = item.allotment.tourCode.split("|")[0];
      if (!acc[eventId]) {
        acc[eventId] = [];
      }
      acc[eventId].push(item);
      return acc;
    }, {});

  // Sort each group by ServiceType.flight and ServiceType.hotel
  Object.keys(orderedItems).forEach(eventId => {
    orderedItems[eventId] = orderBy(orderedItems[eventId],
      [
        item => item.includedServiceTypes.includes(ServiceType.flight),
        item => item.includedServiceTypes.includes(ServiceType.hotel)
      ],
      ['desc', 'desc']);
  });

  return orderedItems;
};
